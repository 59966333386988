import { ScheduleProps } from '@augustine-institute/lib';
import { EVENT_END } from '../../utils/2022-10/config';

const tz = EVENT_END.toLocaleTimeString('en-US', {
  timeZone: 'America/Denver',
  timeZoneName: 'short',
})
  .split(' ')
  .pop();

export const schedule: ScheduleProps[] = [
  {
    time: `2:00 PM ${tz}`,
    title: 'Welcome and Opening Prayer',
  },
  {
    time: `2:05 PM ${tz}`,
    subtime: 'Prof. Lucas Pollice',
    title: 'The Blueprint for the New Evangelization',
    excerpt:
      'The New Evangelization has been a familiar buzzword in the life of the Church for over 40 years.',
    desc: (
      <>
        <p>
          The New Evangelization has been a familiar buzzword in the life of the
          Church for over 40 years. What is unfamiliar for many in the Church is
          that the vision, plan, and blueprint for the New Evangelization begins
          with the documents of Vatican II.
        </p>
        <p>
          In this session, we will discover how Vatican II was a new spirit of
          Pentecost in the life of the Church that has inaugurated the New
          Evangelization.
        </p>
        <p>
          We will also explore how Vatican II, described by St. John Paul II as
          the “great grace and sure compass” for the Church in the Third
          Millennium, can guide our work in our dioceses and parishes to fulfill
          the call of the New Evangelization in our time.
        </p>
      </>
    ),
  },
  {
    time: `2:45 PM ${tz}`,
    subtime: 'Dr. Sean Innerst',
    title: 'Getting the Council: The Catechism as the Key',
    excerpt:
      'With the recent controversies swirling around the German Synod and the revived liturgy wars, some have called the Second Vatican ...',
    desc: (
      <>
        <p>
          With the recent controversies swirling around the German Synod and the
          revived liturgy wars, some have called the Second Vatican Council
          itself into question, suggesting that it can or should be set aside.
        </p>
        <p>
          In this presentation we will look at the Catechism of the Catholic
          Church and the way in which the Church has already given the
          definitive interpretation of the Council in response to erroneous
          claims that it heralds the arrival of a new Church, distinct from the
          Catholic Church originally founded by Christ.
        </p>
        <p>
          This presentation will equip lay leaders to face these important
          questions in their own ministries.
        </p>
      </>
    ),
  },
  {
    time: `3:30 PM ${tz}`,
    title: 'Break',
  },
  {
    time: `3:45 PM ${tz}`,
    subtime: 'Dr. Michael Barber',
    title: 'The Bible as the Soul of Theology',
    excerpt:
      'The Second Vatican Council declared that “the study of the sacred page is ... the soul of sacred theology”.',
    desc: (
      <>
        <p>
          The Second Vatican Council declared that “the study of the sacred page
          is ... the soul of sacred theology” (Dei Verbum §24).
        </p>
        <p>
          What does this mean? Why is the Bible so important for the life and
          mission of the Church? And what concrete steps can we take to ensure
          that, as Pope Francis has insisted, the Scripture remains “a door
          opened to every believer” (Joy of the Gospel §175)?
        </p>
        <p>
          In this informative and entertaining talk, biblical scholar Michael
          Barber looks at these questions and explains why Pope Benedict XVI
          explains the Council’s teaching by saying, “where theology is not
          essentially the interpretation of the Church’s Scripture, such a
          theology no longer has a foundation” (Verbum Domini §35).
        </p>
      </>
    ),
  },
  {
    time: `4:30 PM ${tz}`,
    title: 'Panel Discussion',
    desc: <p>With Profs. Lucas Pollice, Sean Innerst, and Michael Barber.</p>,
  },
  {
    time: `5:00 PM ${tz}`,
    title: 'Concluding Remarks and Closing Prayer',
  },
];
