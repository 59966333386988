import { StaticImage } from 'gatsby-plugin-image';
import { RiCalendar2Fill, RiTimeLine } from 'react-icons/ri';
import { HeadProps } from 'gatsby';
import {
  Tabs,
  TabList,
  Tab,
  TabPanelWrapper,
  TabPanel,
  Schedule,
  Speakers,
} from '@augustine-institute/lib';
import SEO from '../components/Seo';
import Bonus from '../components/Bonus';
import Form from '../components/2022-10/Form';
import { EVENT_START, EVENT_END } from '../utils/2022-10/config';
import EventImage from '../components/2022-10/EventImage';
import { schedule } from '../data/2022-10/schedule';
import { speakers } from '../data/2022-10/speakers';

// * This assumes that event start & end dates are the same day.

const dateString = EVENT_START.toLocaleDateString('en-US', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

// Get the time string - range between EVENT_START and EVENT_END
const timeString = `${EVENT_START.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
})} - ${EVENT_END.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
  timeZoneName: 'short',
})}`;

const startTimeString = EVENT_START.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'America/Denver',
  timeZoneName: 'short',
});

function EventTitle({ className = '' }: { className?: string }) {
  return (
    <div className={`event-title ${className}`}>
      <a
        href="https://www.augustineinstitute.org/graduate-school/graduate-school"
        className="logo"
      >
        <StaticImage
          src="../images/logo-full.png"
          alt="Augustine Institute"
          loading="eager"
          objectFit="contain"
          placeholder="none"
        />
      </a>

      <h1>The Wisdom of Christ and His Church for the Good of Souls</h1>
      <h2 className="h4">A Virtual Conference for Lay Leaders</h2>

      <div className="event-details">
        <ul>
          <li>
            <RiCalendar2Fill title="date" />
            <h3 className="h6">{dateString}</h3>
          </li>
          <li>
            <RiTimeLine title="time" />
            <h3 className="h6">{timeString}</h3>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default function HomePage() {
  const today = new Date();
  const msToEvent = EVENT_START.valueOf() - today.valueOf();
  const minutesToEvent = Math.floor(msToEvent / 1000 / 60);
  const hoursToEvent = Math.floor(msToEvent / 1000 / 60 / 60);
  const daysToEvent = Math.floor(msToEvent / 1000 / 60 / 60 / 24);

  const string =
    // eslint-disable-next-line no-nested-ternary
    daysToEvent > 0
      ? `${daysToEvent} days`
      : hoursToEvent > 0
      ? `${hoursToEvent} hours`
      : `${minutesToEvent} minutes`;

  return (
    <main className="page-2022-10">
      <section className="container page-m-t section-m-b">
        <div className="row">
          <div className="col-xs-12 hide-xs show-sm hide-md">
            <EventTitle className="sm-hide md-show" />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-7">
            <EventTitle className="hide-sm show-md" />
            <div className="event-desc">
              <div className="text-columns">
                <EventImage className="hide-sm" />
                <p>
                  If you are a{' '}
                  <strong>
                    lay leader serving in a parish, diocese, or Catholic
                    apostolate
                  </strong>
                  , join the Augustine Institute faculty for a virtual
                  conference to strengthen you in your role.
                </p>
                <p>
                  You will hear from our experienced faculty as they discuss a
                  renewed vision for evangelization and formation.
                </p>
                <p>
                  As the Church observes the sixtieth anniversary of the opening
                  of the Second Vatican Council, this conference will highlight
                  the way in which the Council’s documents illumine the mission
                  of the Church.
                </p>
                <p>
                  It will invite us into deeper reflection on the centrality of
                  the deposit of the faith in our apostolic work.
                </p>
                <p>
                  This Conference will also highlight our new Master of Arts in
                  Pastoral Theology program for lay leaders, with an optional
                  Concentration in Catechesis, which is available both on-campus
                  and via distance education.
                </p>
                <p>
                  Tune in and join us as we seek to strengthen you in your roles
                  as lay leaders!
                </p>
                <div className="event-img__placeholder" />
              </div>
              <Bonus
                img={<EventImage />}
                imgClassName="hide-xs show-sm"
                textClassName="hide-sm show-md"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-5">
            <div className="registration">
              <div className="registration__inner">
                <div className="registration__dots hide-xs show-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 90 230"
                  >
                    <circle cx="25" cy="25" r="5" fill="#fff" />
                    <circle cx="25" cy="145" r="5" fill="#fff" />
                    <circle cx="65" cy="145" r="5" fill="#fff" />
                    <circle cx="45" cy="25" r="5" fill="#fff" />
                    <circle cx="45" cy="145" r="5" fill="#fff" />
                    <circle cx="85" cy="145" r="5" fill="#fff" />
                    <circle cx="25" cy="45" r="5" fill="#fff" />
                    <circle cx="25" cy="165" r="5" fill="#fff" />
                    <circle cx="65" cy="165" r="5" fill="#fff" />
                    <circle cx="45" cy="45" r="5" fill="#fff" />
                    <circle cx="45" cy="165" r="5" fill="#fff" />
                    <circle cx="85" cy="165" r="5" fill="#fff" />
                    <circle cx="25" cy="65" r="5" fill="#fff" />
                    <circle cx="25" cy="185" r="5" fill="#fff" />
                    <circle cx="65" cy="185" r="5" fill="#fff" />
                    <circle cx="45" cy="65" r="5" fill="#fff" />
                    <circle cx="45" cy="185" r="5" fill="#fff" />
                    <circle cx="85" cy="185" r="5" fill="#fff" />
                    <circle cx="25" cy="85" r="5" fill="#fff" />
                    <circle cx="25" cy="205" r="5" fill="#fff" />
                    <circle cx="65" cy="85" r="5" fill="#fff" />
                    <circle cx="65" cy="205" r="5" fill="#fff" />
                    <circle cx="45" cy="85" r="5" fill="#fff" />
                    <circle cx="45" cy="205" r="5" fill="#fff" />
                    <circle cx="85" cy="85" r="5" fill="#fff" />
                    <circle cx="85" cy="205" r="5" fill="#fff" />
                    <circle cx="25" cy="105" r="5" fill="#fff" />
                    <circle cx="25" cy="225" r="5" fill="#fff" />
                    <circle cx="65" cy="105" r="5" fill="#fff" />
                    <circle cx="65" cy="225" r="5" fill="#fff" />
                    <circle cx="45" cy="105" r="5" fill="#fff" />
                    <circle cx="45" cy="225" r="5" fill="#fff" />
                    <circle cx="85" cy="105" r="5" fill="#fff" />
                    <circle cx="85" cy="225" r="5" fill="#fff" />
                    <circle cx="25" cy="125" r="5" fill="#fff" />
                    <circle cx="65" cy="125" r="5" fill="#fff" />
                    <circle cx="45" cy="125" r="5" fill="#fff" />
                    <circle cx="85" cy="125" r="5" fill="#fff" />
                    <circle cx="5" cy="5" r="5" fill="#fff" />
                    <circle cx="5" cy="125" r="5" fill="#fff" />
                    <circle cx="5" cy="25" r="5" fill="#fff" />
                    <circle cx="5" cy="145" r="5" fill="#fff" />
                    <circle cx="5" cy="45" r="5" fill="#fff" />
                    <circle cx="5" cy="165" r="5" fill="#fff" />
                    <circle cx="5" cy="65" r="5" fill="#fff" />
                    <circle cx="5" cy="185" r="5" fill="#fff" />
                    <circle cx="5" cy="85" r="5" fill="#fff" />
                    <circle cx="5" cy="205" r="5" fill="#fff" />
                    <circle cx="5" cy="105" r="5" fill="#fff" />
                  </svg>
                </div>
                {(minutesToEvent >= 0 && (
                  <>
                    <div className="registration__title">
                      <h2 className="h5" style={{ marginBottom: '0.25em' }}>
                        Event begins in
                      </h2>
                      <h3 className="h2">{string}</h3>
                    </div>
                    <div className="registration__form">
                      <Form />
                    </div>
                  </>
                )) || (
                  <div className="registration__title">
                    <h3 className="h2">Event has ended</h3>
                    <h2 className="h5">Thank you for your participation!</h2>
                  </div>
                )}
              </div>
            </div>
            <Bonus
              img={<EventImage />}
              className="event-bonus--registration hide-xs show-sm hide-md p-m-t"
              imgClassName="hide-xs"
            />
          </div>
        </div>
      </section>
      <section className="container section-m-b">
        <Tabs id="courses">
          <TabList>
            <Tab id="event-schedule">
              <span className="h3">Event Schedule</span>
            </Tab>
            <Tab id="course-of-study2">
              <span className="h3">Speakers</span>
            </Tab>
          </TabList>
          <TabPanelWrapper>
            <TabPanel>
              <p className="narrow">
                This livestream event will start at{' '}
                <strong>
                  {startTimeString} on {dateString}.
                </strong>
              </p>
              <Schedule schedule={schedule} />
            </TabPanel>
            <TabPanel>
              <Speakers speakers={speakers} />
            </TabPanel>
          </TabPanelWrapper>
        </Tabs>
      </section>
    </main>
  );
}

export function Head({ location: { pathname } }: HeadProps) {
  return (
    <SEO
      title="The Wisdom of Christ and His Church for the Good of Souls"
      description="If you are a lay leader serving in a parish, diocese, or Catholic apostolate, join the Augustine Institute faculty for a virtual conference to strengthen you in your role."
      pathname={pathname}
    />
  );
}
