import { SpeakerProps } from '@augustine-institute/lib';
import { StaticImage } from 'gatsby-plugin-image';

export const speakers: SpeakerProps[] = [
  {
    name: 'Professor Lucas Pollice ',
    title:
      'Associate Professor of Theology and Catechetics | Director of the MA in Pastoral Theology Degree Program',
    image: (
      <StaticImage
        src="../../images/speakers/lucas-pollice.jpg"
        alt="Professor Lucas Pollice "
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Professor Pollice is an Associate Professor of Theology and Catechetics
        and Director of the MA in Pastoral Theology Degree Program at the
        Augustine Institute Graduate School. He has over twenty years of
        extensive leadership, ministry, and teaching experience in the areas of
        catechetics and pastoral theology at both the parish and diocesan
        levels. Professor Pollice was awarded the Benemerenti Medal by Pope
        Benedict XVI for outstanding service to the Church in 2011. He completed
        his Master’s in Theological Studies from St. Mary’s College of Ave Maria
        University.
      </p>
    ),
  },
  {
    name: 'Dr. Sean Innerst',
    title: 'Professor of Theology and Catechetics',
    image: (
      <StaticImage
        src="../../images/speakers/sean-innerst.jpg"
        alt="Dr. Sean Innerst"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Sean Innerst is a Professor of Theology and Catechetics at the Augustine
        Institute Graduate School. He has been teaching theology,
        evangelization, and catechesis to seminarians, college students,
        missionaries, graduate students, DREs, deacon candidates, and catechists
        for over twenty-five years. He helped found two important Catholic
        institutions in Denver: St. John Vianney Theological Seminary, as its
        founding provost, and the Augustine Institute, as its founding academic
        dean. He earned his doctorate in theology from the University of South
        Africa.
      </p>
    ),
  },
  {
    name: 'Dr. Michael Barber',
    title: 'Professor of Sacred Scripture and Theology',
    image: (
      <StaticImage
        src="../../images/speakers/michael-barber.jpg"
        alt="Dr. Michael Barber"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        As a Professor of Sacred Scripture and Theology at the Augustine
        Institute Graduate School, Dr. Barber specializes in the New Testament,
        specifically the Gospels, St. Paul’s letters, and the Book of
        Revelation. He is the author of numerous scholarly articles and
        publications, including,{' '}
        <em>Paul, A New Covenant Jew: Rethinking Pauline Theology</em>{' '}
        (Eerdmans, 2019), co-written with Brant Pitre and John Kincaid and{' '}
        <em>
          The True Meaning of Christmas: The Birth of Jesus and the Origins of
          the Season
        </em>{' '}
        (Augustine Institute Press, 2021). Dr. Barber earned his doctorate in
        theology from Fuller Theological Seminary.
      </p>
    ),
  },
];
