import { StaticImage } from 'gatsby-plugin-image';

export default function EventImage({ className = '' }: { className?: string }) {
  return (
    <div className={`event-img ${className}`}>
      <div className="event-img__inner">
        <StaticImage
          src="../../images/2022-10/home-img-1.jpg"
          alt=""
          placeholder="blurred"
        />
      </div>
    </div>
  );
}
