import striptags from 'striptags';
import { EVENT_END, EVENT_START } from './config';

function removeEmptySpaces(str: string) {
  return str.replace(/(\r\n|\n|\r)/gm, '');
}

const formatHTMLToPlain = (string: string, escape: boolean = false) => {
  const lb = escape ? '\\n' : '\n';
  return striptags(
    removeEmptySpaces(string)
      .replace(/<\/p>/g, `${lb}${lb}`)
      .replace(/<br\/>|<br \/>/g, `${lb}`)
  );
};
const formatGoogleDate = (iso: string) => iso.replace(/-|:|.000/g, '');

export function getCalendarLink() {
  const eventStart = EVENT_START;
  const eventEnd = EVENT_END;
  const eventName = 'The Wisdom of Christ and His Church for the Good of Souls';
  const encodedEventName = encodeURIComponent(eventName);

  const dateString = EVENT_START.toLocaleDateString('en-US', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  // Get the time string - range between EVENT_START and EVENT_END
  const timeString = `${EVENT_START.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    timeZone: 'America/Denver',
  })} - ${EVENT_END.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    timeZone: 'America/Denver',
    timeZoneName: 'short',
  })}`;

  const getEventDetails = (
    full: boolean = true
  ) => `<p>Thank you for registering for the Augustine Institute’s virtual conference for lay leaders.</p>
<p>We look forward to seeing you on <strong>${dateString}</strong> from <strong>${timeString}</strong>. Please use the link below to tune in and join us as we seek to strengthen you in your roles as lay leaders! </p>
<p>Livestream Event Link - <a href="https://augustineinstitute-org.zoom.us/j/96682141411">https://augustineinstitute-org.zoom.us/j/96682141411</a>.</p>
<p>During the virtual conference, you will hear from members of the Augustine Institute’s experienced faculty as they provide insights into how to better use the tools God has provided, what the Church is telling us with the revival of the ancient ministry of the Catechist, and how the study of theology and effective pastoral practice contribute to fruitful Church ministry.</p>
<p>There will also be a Q&A panel discussion.</p>
<p>If you have any questions before the event, do not hesitate to <a href="https://www.augustineinstitute.org/about-pages/contact">contact us</a>.</p>
<p>In Christ,<br />The Augustine Institute Graduate School</p>`;

  const eventDetailsHTML = encodeURIComponent(
    removeEmptySpaces(getEventDetails())
  );
  const eventDetailsPlain = encodeURIComponent(
    formatHTMLToPlain(getEventDetails())
  );
  const eventDetailsPlainOutlook = encodeURIComponent(
    formatHTMLToPlain(getEventDetails(false))
  );
  const eventDetailsICS = formatHTMLToPlain(getEventDetails(), true);
  const eventURL = 'https://layleadersconference.augustine.edu';
  const formattedEventStart = encodeURIComponent(
    formatGoogleDate(eventStart.toISOString())
  );
  const formattedEventEnd = encodeURIComponent(
    formatGoogleDate(eventEnd.toISOString())
  );

  const encodedEventStart = encodeURIComponent(eventStart.toISOString());
  const encodedEventEnd = encodeURIComponent(eventEnd.toISOString());
  // &ctz=America/Denver

  const google = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedEventName}&dates=${formattedEventStart}/${formattedEventEnd}&details=${eventDetailsHTML}&sprop=&sprop=name:`;
  const yahoo = `http://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodedEventName}&st=${formattedEventStart}&et=${formattedEventEnd}&desc=${eventDetailsPlain}`;
  const outlook = `https://outlook.live.com/calendar/0/deeplink/compose?startdt=${encodedEventStart}&enddt=${encodedEventEnd}&subject=${encodedEventName}&body=${eventDetailsPlainOutlook}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent`;
  const office = `https://outlook.office.com/calendar/0/deeplink/compose?startdt=${encodedEventStart}&enddt=${encodedEventEnd}&subject=${encodedEventName}&body=${eventDetailsPlainOutlook}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent`;
  const ics = `BEGIN:VCALENDAR\n
VERSION:2.0\n
BEGIN:VEVENT\n
URL:${eventURL}\n
DTSTART:${formattedEventStart}\n
DTEND:${formattedEventEnd}\n
SUMMARY:${eventName}\n
DESCRIPTION:${eventDetailsICS}\n
LOCATION:\n
END:VEVENT\n
END:VCALENDAR`;

  return {
    google,
    yahoo,
    outlook,
    office,
    ics,
  };
}
